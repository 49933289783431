.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

#main-logo {
  max-width: 100px;
  /*height: calc(40vh - 175px);*/
}

.pcoded-main-container {
  margin-left: 295px;
}

.pcoded-navbar {
  width: 295px;
  top: 15px;
}

.pcoded-navbar.navbar-collapsed:hover {
  width: 295px !important;
}

[class*="_TableHeadRow"] {
  background: #ecf0f5 !important;
  /*margin: 6px;*/
  padding: 15px 0;
}

[class*="_TableHeadRow"] [class*="_TableCol"] {
  font-size: 12px;
  color: black;
  text-transform: uppercase;
  /* padding-left: 0; */
}

.participant-data-table-custom [class*="_TableHeadRow"] [class*="_TableCol"] {
  font-size: 12px;
  color: black;
  text-transform: uppercase;
  padding-left: 0;
}

.participant-data-table-custom .rdt_TableCol:first-child {
  padding-left: 16px;
}

[class*="_TableBody"] div[role="row"] {
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  border-radius: 0.25rem;
  position: relative;
  /*margin: 6px;*/
  /*padding: 15px 0;*/
  width: calc(100% - 0px);
}

[class*="_TableBody"] div[role="row"]:hover {
  box-shadow: 0 0 6px 0 #4680ff;
  /*background: #4680ff;*/
  /*color: white;*/
}

/*[class*="_TableBody"] div[role = "row"]:hover h6{*/
/*    color: white;*/
/*}*/

[class*="_TableBody"] .overlay-edit {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  background: #4680ff;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[class*="_TableBody"] div[role="row"]:hover .overlay-edit {
  opacity: 1;
}

/*.navbar-wrapper ul.nav > li:last-child{*/
/*    display: none;*/
/*}*/
.cover-image-block {
  border: 5px solid white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.user-card .change-profile .profile-dp.user-dp {
  width: 80px;
  height: 80px;
}

.user-card .change-profile .profile-dp.user-dp img {
  height: 68px;
  width: 68px;
  object-fit: cover;
}

.studio-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  object-fit: contain;
  background: grey;
  /*margin-right: 10px;*/
}

.cover-img-block {
  background: grey;
}

.cover-img-block img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.tbl-icons {
  font-size: 22px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/*maps*/
.pac-container {
  z-index: 9999 !important;
}

.select-style {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.badge {
  white-space: normal;
}

.swal2-icon.swal2-question,
.swal2-icon.swal2-warning {
  border-color: red !important;
  color: red !important;
}

/*.swal2-styled.swal2-confirm {*/
/*    background: red !important;*/
/*}*/
.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.react-datepicker-popper {
  z-index: 3;
}

.custom-control-input-mine {
  position: absolute;
  left: calc(50% - 1rem);
  z-index: -1;
  width: 2rem;
  height: 2rem;
  opacity: 0;
  top: -5px;
}

.hBLBhZ div:first-child {
  text-overflow: inherit !important;
  white-space: normal !important;
}

[class*="_TableBody"] div[role="row"] {
  box-sizing: content-box;
}

.btn-primary {
  color: #fff;
  background-color: #f07631;
  border-color: #f07631;
}

.btn-primary:hover {
  color: #fff;
  background-color: #e66a25;
  border-color: #e66a25;
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:active {
  border-color: #f07631 !important;
  box-shadow: none !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #000000 !important;
}

.icon-position {
  position: relative;
  top: 5px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #f07631;
  border-color: #f07631;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #f07631;
  border-color: #f07631;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f07631;
  background-color: #f07631;
}

.preview-img {
  max-width: 160px;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 5px auto;
  float: right;
}

.preview-img img {
  max-width: 150px;
  max-height: 110px;
}

.preview-img .img-close {
  padding: 7px;
  /* background: #F07631; */
  font-weight: 700;
  border: 1px solid #f07631;
  display: inline-block;
  position: absolute;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 1;
  border-radius: 30px;
  right: 5px;
}
.fileUploader {
  width: 280px !important;
  float: right;
}
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
}
.fileContainer .chooseFileButton {
  padding: 6px 15px;
  font-size: 10px;
  margin: 10px 0;
}
.equ-input {
  width: 6.5%;
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.custom-hr {
  margin-top: 2rem;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.reconnect-text {
  color: #ff0000;
  font-size: 12px;
}

.sort-text {
  right: 15px;
  top: 41px;
  color: #ff0000;
  font-size: 12px;
  position: absolute;
}
.message-modal-input {
  width: 15%;
  text-align: center;
}

.assignment-radio [type="radio"]:checked,
.assignment-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.assignment-radio [type="radio"]:checked + label,
.assignment-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.assignment-radio [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #4680ff;
  border-radius: 100%;
  background: #fff;
}
.assignment-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #ff0000;
  border-radius: 100%;
  background: #fff;
}
.assignment-radio [type="radio"]:checked + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #4680ff;
  position: absolute;
  top: 5px;
  left: 5px;
  border: 2px solid #4680ff;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.assignment-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #ff0000;
  position: absolute;
  top: 5px;
  left: 5px;
  border: 2px solid #ff0000;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.assignment-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.assignment-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.scroll-v {
  overflow-y: scroll;
  height: 400px;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 0;
}
.topic {
  cursor: pointer;
  font-weight: bold;
}
.topic:hover {
  color: #f07631;
}
.participants-th .kibde {
  max-height: 460px;
}
.unitInHeader {
  color: #f07631;
  font-size: 9px;
  text-transform: lowercase;
}
.white-space {
  white-space: pre-line;
}
.tb-scroll {
  overflow-y: auto;
  max-height: 40px;
  min-width: 100%;
}

.tb-scroll-pdi {
  overflow-y: auto;
  max-height: 75px;
  min-width: 300px;
}

.swal2-backdrop-show {
  z-index: 9999 !important;
}
.chat-card .msg {
  max-width: 50%;
}
.input-behaviour .form-control {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  height: 38px;
}
.width-input {
  width: 60% !important;
  display: inline-block !important;
}
.dnsAhI {
  overflow: visible !important;
  white-space: unset !important;
  text-overflow: unset !important;
}
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.h90-modal-card .modal-content {
  height: 100% !important;
  max-height: none !important;
}
.quill-class .ql-container {
  min-height: 15vh;
}
.z-index-for-checkbox .rdt_TableHead {
  z-index: 2;
}

.apexcharts-canvas {
  z-index: 0;
}
.react-datepicker {
  z-index: 2;
}
.rdt_TableBody{
  min-height: 200px;
}

.calories-title-bg {
  background-color: #f0e7e7;
}
.remove-padding-table .rdt_TableBody{
  min-height: 0px;
}
.textarea-notes {
  padding: 5px !important;
  border: 1px solid;
}

.css-26l3qy-menu {
  z-index: 3 !important;
}
/*.rdt_Table {
  z-index: 99;
}
.react-select__menu {
  z-index: 100;
}*/
/*.react-select__menu-list {*/
/*  z-index: 2;*/
/*}*/
@media (min-width: 992px) {
  .custom-width {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
@media (min-width: 1200px) {
  .custom-width {
    flex: 0 0 27.33333%;
    max-width: 27.33333%;
  }
}
@media (min-width: 1500px) {
  .custom-width {
    flex: 0 0 20.33333% !important;
    max-width: 20.33333% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
.otp input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.otp input[type=number] {
	-moz-appearance: textfield;
}